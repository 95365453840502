import Link from "next/link"
import PropTypes from "prop-types"
import React from "react"

const NavLink = ({ href, children, active }) => (
  <Link href={href}>
    <a
      className={`hover:text-pink h-full flex items-center focus:outline-none focus:text-pink px-2 whitespace-no-wrap${
        active ? " text-pink" : ""
      }`}
    >
      {children}
    </a>
  </Link>
)

NavLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
}

export default NavLink
