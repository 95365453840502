import Head from "next/head"
import PropTypes from "prop-types"
import React from "react"

import { useAppContext } from "./app-context"

function SEO({ description, meta, title, image, path }) {
  const { host } = useAppContext()
  const siteTitle = `la fille d’O lingerie`
  const siteDescription = `la fille d’O lingerie, qui culotte a pouvoir a`

  const metaTitle = title || siteTitle
  const metaDescription = description || siteDescription
  const metaImage = image || `//${host}/images/logo.jpg`

  const pageTitle = title ? `${title} • ${siteTitle}` : siteTitle
  const searchEngineMeta = [
    {
      name: "description",
      content: metaDescription,
    },
  ]

  const facebookMeta = [
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:title",
      content: metaTitle,
    },
    {
      property: "og:description",
      content: metaDescription,
    },
    {
      property: "og:image",
      content: metaImage,
    },
  ]

  const twitterMeta = [
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:title",
      content: metaTitle,
    },
    {
      name: "twitter:description",
      content: metaDescription,
    },
    {
      name: "twitter:image",
      content: metaImage,
    },
  ]

  const metaTags = [
    ...searchEngineMeta,
    ...facebookMeta,
    ...twitterMeta,
    ...meta,
  ]

  return (
    <Head>
      <title>{pageTitle}</title>
      {metaTags.map(({ name, property, content }) => {
        if (property) {
          return <meta key={property} property={property} content={content} />
        }
        return <meta key={name} name={name} content={content} />
      })}
      {path && <link rel="canonical" href={`//${host}${path}`} />}
      <link rel="preconnect" href="https://player.vimeo.com" />
      <link rel="preconnect" href="https://vod-progressive.akamaized.net" />
      <link rel="preconnect" href="https://i.vimeocdn.com" />
      <link rel="preconnect" href="https://www.google-analytics.com" />
    </Head>
  )
}

SEO.defaultProps = {
  meta: [],
  description: undefined,
  title: undefined,
  image: undefined,
  path: undefined,
}

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.string,
  path: PropTypes.string,
}

export default SEO
