import React from "react"

import CartNav from "./cart-nav"
import Chat from "./chat"
import Nav from "./nav"
import SecondaryNav from "./secondary-nav"

const Header = () => (
  <header className="flex bg-white w-full h-10">
    <div className="relative flex-1 overflow-hidden">
      <div className="overflow-x-auto scrolling-touch overflow-y-hidden h-24 pl-4 md:pl-10">
        <div className="flex h-10">
          <Nav />
          <div className="h-full w-4 flex-shrink-0"></div>
        </div>
      </div>
      <div className="absolute top-0 right-0 w-10 h-10 lfo-overflow-x-image" />
    </div>
    <div className="flex pr-4 md:pr-10 -mr-2">
      <SecondaryNav />
      <CartNav />
      <Chat />
    </div>
  </header>
)

export default Header
