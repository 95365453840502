import React from "react"

import { useAppContext, useAppContextDispatch } from "./app-context"

const AudioNotification = () => {
  const { audioNotAllowed } = useAppContext()
  const appDispatch = useAppContextDispatch()

  const handleClick = () => {
    const elements = document.getElementsByTagName("video")
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i]
      element.muted = false
    }
    appDispatch(state => ({
      ...state,
      audioNotAllowed: false,
    }))
  }

  if (!audioNotAllowed) {
    return null
  }

  return (
    <button
      className="fixed top-0 w-full z-50 text-black w-full bg-gray-600 text-left px-4 md:px-8 pt-10 md:pt-16 pb-6 md:pb-10 md:text-2xl border-b-2 border-solid border-black focus:outline-none leading-tight"
      onClick={handleClick}
    >
      Your sound is muted. Please{" "}
      <span className="underline">click to unmute it</span>.
    </button>
  )
}

export default AudioNotification
