import PropTypes from "prop-types"
import React from "react"

import * as analytics from "../lib/google-analytics"
import { useAppContext } from "./app-context"

const GoogleAnalytics = ({ id }) => {
  const { acceptsCookies } = useAppContext()

  React.useEffect(() => {
    if (!acceptsCookies) return

    analytics.init(id)
    analytics.pageview(window.location.pathname)
  }, [acceptsCookies, id])

  return null
}

GoogleAnalytics.propTypes = {
  id: PropTypes.string.isRequired,
}

export default GoogleAnalytics
