import React from "react"
import { useInterval } from "react-use"

const SCRIPT_ID = "ze-snippet"

const checkIsOnline = callback => {
  window.$zopim &&
    window.$zopim.livechat &&
    window.$zopim.livechat.setOnStatus(status => callback(status === "online"))
}

const Chat = () => {
  const [lazyLoad, setLazyLoad] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  const [online, setOnline] = React.useState(false)

  React.useEffect(() => {
    const timeout = setTimeout(() => setLazyLoad(true), 5000)
    return () => clearTimeout(timeout)
  }, [])

  React.useEffect(() => {
    if (document.getElementById(SCRIPT_ID)) {
      setLoaded(true)
    } else if (lazyLoad) {
      const script = document.createElement("script")
      script.id = SCRIPT_ID
      script.src =
        "https://static.zdassets.com/ekr/snippet.js?key=936f26e7-ffb7-4059-b9bd-4174e3644e15"
      script.defer = true

      const onScriptLoad = () => {
        setLoaded(true)
      }

      const onScriptError = () => {
        script.remove()
      }

      script.addEventListener("load", onScriptLoad)
      script.addEventListener("error", onScriptError)

      // Add script to document body
      document.body.appendChild(script)

      // Remove event listeners on cleanup
      return () => {
        script.removeEventListener("load", onScriptLoad)
        script.removeEventListener("error", onScriptError)
      }
    }
  }, [lazyLoad])

  useInterval(() => checkIsOnline(setOnline), 60 * 1000)

  React.useEffect(() => {
    if (loaded && window.zE) {
      window.zE("webWidget", "hide")
      const timer = setTimeout(() => checkIsOnline(setOnline), 1000)
      return () => clearTimeout(timer)
    }
  }, [loaded])

  const handleClick = () => {
    window.zE && window.zE.activate()
  }

  if (!loaded) {
    return null
  }

  return (
    <div className="h-full">
      <button
        className="text-tiny block flex h-full px-2 focus:outline-none items-center font-bold"
        onClick={handleClick}
      >
        <span
          className={`mr-1 rounded-full block bg-pink w-2 h-2 opacity-50${
            online ? " lfo-hal-3000" : ""
          }`}
        ></span>
        <span>chat</span>
      </button>
    </div>
  )
}

export default Chat
