import { useRouter } from "next/router"
import React from "react"

import { getItem, setItem } from "../lib/cache"
import Storyblok, { getStoryblokParams } from "../lib/storyblok"
import NavLink from "./nav-link"

const getStoriesCount = async () => {
  const key = "storiesCount"
  const cachedCount = getItem(key)
  if (cachedCount) {
    return cachedCount
  }
  let count
  try {
    const stories = await Storyblok.get("cdn/stories/stories", {
      ...getStoryblokParams(),
    })
    count = stories.data.story.content.main.length
    setItem(key, count)
  } catch (error) {}

  return count
}

const Nav = () => {
  const router = useRouter()
  const [storiesCount, setStoriesCount] = React.useState(null)

  React.useEffect(() => {
    ;(async function init() {
      const count = await getStoriesCount()
      setStoriesCount(count)
    })()
  }, [])

  return (
    <nav className="h-full">
      <ul className="flex font-bold text-tiny h-full -ml-2">
        <li className="h-full">
          <NavLink
            href="/"
            active={
              router.pathname === "/" ||
              router.pathname === "/collections/[slug]" ||
              router.query.collection !== undefined
            }
          >
            collections
          </NavLink>
        </li>
        <li className="h-full">
          <NavLink
            href="/products"
            active={
              router.pathname === "/products" ||
              (router.pathname === "/products/[slug]" &&
                router.query.collection === undefined)
            }
          >
            products + search
          </NavLink>
        </li>
        <li className="h-full">
          <NavLink
            href="/stories"
            active={
              router.pathname === "/stories" ||
              router.pathname === "/stories/[slug]"
            }
          >
            stories
            {storiesCount && (
              <span className="flex items-center justify-center w-5 h-5 border border-solid border-current rounded-full leading-none ml-1 tracking-tighter box-content">
                {storiesCount}
              </span>
            )}
          </NavLink>
        </li>
        <li className="h-full">
          <NavLink href="/about" active={router.pathname === "/about"}>
            about
          </NavLink>
        </li>
        <li className="h-full">
          <NavLink href="/contact" active={router.pathname === "/contact"}>
            stores + contact
          </NavLink>
        </li>
      </ul>
    </nav>
  )
}

export default Nav
