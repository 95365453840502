import React from "react"

import { showCart, useCartContext } from "./cart-context"

const CartNav = () => {
  const [cartState, cartDispatch] = useCartContext()
  const count = (
    (cartState.checkout && cartState.checkout.lineItems) ||
    []
  ).reduce((prev, next) => prev + next.quantity, 0)

  const handleClick = () => {
    showCart(cartDispatch)
  }

  if (count === 0) {
    return null
  }

  return (
    <div className="h-full">
      <button
        className="text-tiny block flex h-full px-2 focus:outline-none items-center font-bold"
        onClick={handleClick}
      >
        <span className="flex items-center text-black overflow-hidden leading-none mr-1 font-bold border border-solid border-black flex align-center justify-center rounded-full block bg-pink w-5 h-5">
          {count}
        </span>
        cart
      </button>
    </div>
  )
}

export default CartNav
