import { useRouter } from "next/router"
import React from "react"

import NavLink from "./nav-link"

const SecondaryNav = () => {
  const router = useRouter()

  return (
    <div className="font-bold text-tiny h-full">
      <NavLink href="/faq" active={router.pathname === "/faq"}>
        faq
      </NavLink>
    </div>
  )
}

export default SecondaryNav
