import PropTypes from "prop-types"
import React from "react"

import AudioNotification from "./audio-notification"
import CartDialog from "./cart-dialog"
import CookieBar from "./cookie-bar"
import FacebookPixel from "./facebook-pixel"
import GoogleAnalytics from "./google-analytics"
import Header from "./header"
import Notification from "./notification"

const Layout = ({ children, hideCookieBar }) => (
  <div className="pb-10 font-sans antialiased">
    <div className="fixed bottom-0 w-full border-t border-solid border-black z-40">
      <Header />
    </div>
    {children}
    <CartDialog />
    <Notification />
    <AudioNotification />
    {hideCookieBar ? null : <CookieBar />}
    <FacebookPixel id="1158877860961246" />
    <GoogleAnalytics id="UA-123689821-1" />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideCookieBar: PropTypes.bool,
}

Layout.defaultProps = {
  hideCookieBar: false,
}

export default Layout
