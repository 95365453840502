import { DialogContent, DialogOverlay } from "@reach/dialog"
import Link from "next/link"
import React from "react"

import currencyFormat from "../lib/currency-format"
import { hideCart, removeCartItem, useCartContext } from "./cart-context"
import { trackInitiateCheckout } from "./facebook-pixel"

const CartDialog = () => {
  const [cartState, cartDispatch] = useCartContext()
  const [termsChecked, setTermsChecked] = React.useState(false)
  const showDialog = cartState.show
  const items =
    cartState.checkout &&
    cartState.checkout.lineItems
      .filter(item => item.variant)
      .map(item => {
        const size = item.variant.selectedOptions.filter(
          option => option.name === "Size"
        )[0]
        const color = item.variant.selectedOptions.filter(
          option => option.name === "Color"
        )[0]

        return {
          id: item.id,
          title: item.title,
          variant: item.variant,
          size,
          color,
          quantity: item.quantity,
          price: item.variant.price,
        }
      })

  const handleDismiss = () => {
    hideCart(cartDispatch)
  }

  const handleCheckboxChange = () => {
    setTermsChecked(value => !value)
  }

  const handleCheckoutClick = () => {
    trackInitiateCheckout()
    window.location = cartState.checkout.webUrl
  }

  return (
    <DialogOverlay
      isOpen={showDialog}
      onDismiss={handleDismiss}
      className="font-mono antialiased z-50 flex flex-col items-center justify-center"
      style={{
        backgroundColor: "rgba(246, 151, 137, .7)",
      }}
    >
      <DialogContent
        aria-label="cart"
        style={{
          width: "auto",
          margin: "0",
          padding: "0",
          background: "transparent",
        }}
      >
        <div className="bg-white border-solid border-black border-2 max-w-3xl min-w-screen-50 md:mx-auto max-h-screen p-4 md:p-8 m-4 overflow-auto">
          {items && items.length > 0 ? (
            <div>
              <table className="table-auto w-full text-xs md:text-tiny leading-tight">
                <tbody>
                  {items.map((item, index) => (
                    <React.Fragment key={item.id}>
                      <tr>
                        <td className="w-full">
                          {item.title}
                          <br />
                          {item.size
                            ? `size: ${item.size.value}`
                            : item.price.amount}
                          {Boolean(item.color) && (
                            <React.Fragment>
                              <br />
                              color: {item.color.value}
                            </React.Fragment>
                          )}
                        </td>
                        <td className="text-right pl-4 md:pl-20 align-top">{`${item.quantity}pc`}</td>
                        <td className="text-right pl-4 md:pl-10 align-top">{`${currencyFormat(
                          item.price.amount
                        )}–`}</td>
                        <td className="text-right pl-4 md:pl-10 align-top">
                          <button
                            className="-mt-1 focus:outline-none"
                            onClick={() =>
                              removeCartItem(cartDispatch, cartState, item)
                            }
                          >
                            <svg
                              height="24"
                              viewBox="0 0 26 26"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g
                                fill="none"
                                fillRule="evenodd"
                                stroke="#000"
                                transform="translate(1 1)"
                              >
                                <circle cx="12" cy="12" r="12" />
                                <path
                                  d="m7.5 7.5 8.515 8.515m.485-8.515-8.515 8.515"
                                  strokeLinecap="square"
                                  strokeWidth="2"
                                />
                              </g>
                            </svg>
                          </button>
                        </td>
                      </tr>
                      {index < items.length - 1 ? (
                        <>
                          <tr>
                            <td
                              colSpan="3"
                              className="border-solid border-black border-b-2 border-dashed"
                            >
                              &nbsp;
                            </td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td colSpan="3">&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </>
                      ) : (
                        <tr>
                          <td>&nbsp;</td>
                          <td
                            colSpan="2"
                            className="border-solid border-black border-b-2 border-dashed"
                          >
                            &nbsp;
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                  <tr>
                    <td></td>
                    <td
                      colSpan="2"
                      className="border-solid border-black border-b-2 border-dashed"
                    ></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan="4">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td className="text-right">SUBTOTAL</td>
                    <td className="text-right pl-4 md:pl-10">{`${currencyFormat(
                      cartState.checkout.totalPrice.amount
                    )}–`}</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td colSpan="4">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <div className="flex md:justify-end md:items-center">
                        <span className="md:text-base leading-tight">
                          taxes and shipping costs will be calculated at
                          checkout
                        </span>
                      </div>
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td colSpan="4">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <input
                        type="checkbox"
                        id="terms"
                        className="hidden"
                        onChange={handleCheckboxChange}
                      />
                      <label
                        htmlFor="terms"
                        className="flex md:justify-end md:items-center"
                      >
                        <span className="block flex-shrink-0 w-5 h-5 border-2 border-solid border-black mr-2">
                          {termsChecked ? (
                            <span className="block h-full w-full rounded-full bg-black border-2 border-white" />
                          ) : null}
                        </span>
                        <span className="block md:text-base leading-tight">
                          I agree with the{" "}
                          <Link href="/tac">
                            <a target="_blank" className="underline">
                              terms and conditions
                            </a>
                          </Link>
                        </span>
                      </label>
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td colSpan="4">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="text-right">
                      <button
                        className={
                          termsChecked
                            ? "inline-block text-xl md:text-2xl px-4 md:px-6 py-2 md:py-3 bg-black text-white hover:bg-pink focus:outline-none"
                            : "inline-block text-xl md:text-2xl px-4 md:px-6 py-2 md:py-3 bg-black text-white opacity-50 cursor-not-allowed"
                        }
                        onClick={handleCheckoutClick}
                        disabled={!termsChecked}
                      >
                        CHECKOUT
                      </button>
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div>your cart is empty</div>
          )}
        </div>
      </DialogContent>
    </DialogOverlay>
  )
}

export default CartDialog
