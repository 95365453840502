import PropTypes from "prop-types"
import React from "react"

import { isClient } from "../lib/util"
import { useAppContext } from "./app-context"

export const trackAddToCart = () => {
  if (isClient && typeof window.fbq !== "undefined") {
    fbq("track", "AddToCart")
  }
}

export const trackInitiateCheckout = () => {
  if (isClient && typeof window.fbq !== "undefined") {
    fbq("track", "InitiateCheckout")
  }
}

const FacebookPixel = ({ id }) => {
  const { acceptsCookies } = useAppContext()

  React.useEffect(() => {
    if (!acceptsCookies || window.fbq) return

    const init = (f, b, e, v, n, t, s) => {
      if (f.fbq) return
      n = f.fbq = function() {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = "2.0"
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    }

    init(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    )
    fbq("init", `${id}`)
    fbq("track", "PageView")
  }, [acceptsCookies, id])

  return null
}

FacebookPixel.propTypes = {
  id: PropTypes.string.isRequired,
}

export default FacebookPixel
