import React from "react"

import { useNotificationState } from "./notification-context"

const Notification = () => {
  const notification = useNotificationState()

  if (!notification) {
    return null
  }

  return (
    <div className="fixed top-0 w-full z-50 text-white w-full bg-pink py-4 md:py-5 font-bold text-center">
      {notification}
    </div>
  )
}

export default Notification
