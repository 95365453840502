const cache = {}

export const setItem = (key, value) => {
  if (typeof window === "undefined") return
  cache[key] = value
}

export const getItem = key => {
  if (typeof window === "undefined") return undefined
  return cache[key]
}
