import React from "react"
import { useLockBodyScroll } from "react-use"

import { useAppContext, useAppContextDispatch } from "./app-context"

const CookieBar = () => {
  const { acceptsCookies } = useAppContext()
  const dispatch = useAppContextDispatch()

  useLockBodyScroll(!acceptsCookies)

  const handleClick = () => {
    fetch(`/api/cookie-accept`, {
      method: "GET",
    }).then(response => {
      if (response.ok) {
        dispatch(value => ({
          ...value,
          acceptsCookies: true,
        }))
      }
    })
  }

  if (acceptsCookies) {
    return null
  }

  return (
    <div className="fixed inset-0 z-50 lfo-bg-black-5 flex items-end md:items-start md:p-24">
      <div className="bg-pink text-white font-mono md:w-112 max-w-full text-sm leading-snug p-4 flex items-start">
        <p className="flex-auto">
          hello, here are our{" "}
          <a href="tac" target="_blank" className="underline">
            terms of service
          </a>{" "}
          and{" "}
          <a href="/privacy" target="blank" className="underline">
            privacy and cookie statements
          </a>
          . we use regular and analytical cookies to make sure you have the best
          time possible and third-party cookies for ad purposes. did we say the
          word cookies enough? (cookies.)
        </p>
        <button
          className="focus:outline-none underline ml-8 md:ml-16"
          onClick={handleClick}
        >
          okay
        </button>
      </div>
    </div>
  )
}

export default CookieBar
